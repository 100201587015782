import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import Icons from "../components/Icons"
import DecorativePhoto from "../components/DecorativePhoto"
import Brands from "../components/Brands"
import BrandImages from "../components/BrandImages"
import Contact from "../components/Contact"

import "../scss/global.scss"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
    <Icons />
    <DecorativePhoto />
    <Brands />
    <BrandImages />
    <Contact />
  </Layout>
)

export default IndexPage
