import React from "react"

import logoVipcoIndustries from "../../images/logo-vipco-industries.svg"
import logoVipcoVisualBoards from "../../images/logo-vipco-visual-boards.svg"
import logoVipcoDecorative from "../../images/logo-vipco-decorative.svg"
import styles from "./index.module.scss"

const Brands = () => (
  <section className={styles.brandSection}>
    <div className={styles.brandPreamble}>
      <h2>Meet our brands</h2>
      <p>
        The Vipco Group of Companies offers high-quality, cost-effective
        building solutions that incorporate both form and function into products
        that you can trust for your interior design and construction projects.
      </p>
      <p>
        With expertise in developing pre-finished products for use in a variety
        of projects like housing accommodations including modular and mobile as
        well as solutions for your interior design and display requirements, our
        knowledgeable teams are equipped to provide the best products for your
        projects.{" "}
      </p>
    </div>

    <div className={styles.brands}>
      <div className={styles.brandWrapper}>
        <div className={styles.brand}>
          <img src={logoVipcoIndustries} alt={"Vipco Industries Ltd."} />
          <p>
            Pre-finished products ready to install and customizable for
            commercial building projects.
          </p>
          <a href={"https://vipco.ca/"} className={styles.siteLink}>
            Go to Vipco Industries Site
          </a>
        </div>
      </div>

      <div className={styles.brandWrapper}>
        <div className={styles.brand}>
          <img src={logoVipcoDecorative} alt={"Vipco Decorative Panels"} />
          <p>
            Premium decorative paneling in matt and high-gloss finish for
            commercial and residential design.
          </p>
          <a href={"http://vdpco.ca/"} className={styles.siteLink}>
            Go to Vipco Decorative Panels Site
          </a>
        </div>
      </div>
    </div>
  </section>
)

export default Brands
