import React from "react"

import styles from "./index.module.scss"
import iconDesign from "../../images/icon-design.svg"
import iconBuild from "../../images/icon-build.svg"
import iconKnowledge from "../../images/icon-knowledge.svg"

const Icons = () => (
  <section className={styles.iconsSection}>
    <h2>The Vipco Standard</h2>
    <div className={styles.iconsDescription}>
      <p>
        Vipco has over 50 years of experience manufacturing a variety of
        pre-finished building materials and has become known as the leader in
        product design and development dedicated to creating top-shelf,
        cost-effective building product solutions.
      </p>
    </div>
    <div className={styles.iconWrapper}>
      <div className={styles.icon}>
        <img src={iconDesign} alt="" />
        HIGH-END DESIGN
      </div>
      <div className={styles.icon}>
        <img src={iconBuild} alt="" />
        QUALITY BUILD
      </div>
      <div className={styles.icon}>
        <img src={iconKnowledge} alt="" />
        EXPERT KNOWLEDGE
      </div>
    </div>
  </section>
)

export default Icons
