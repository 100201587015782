import React from "react"

import styles from "./index.module.scss"

const Hero = () => (
  <section className={styles.hero}>
    <h1 className={styles.heroHeading}>WE ARE VIPCO GROUP.</h1>
    <div className={styles.heroDescription}>
      <p>
        Creating high-quality building supply and interior design products for
        Canadian designers, property managers and construction leaders since
        1971.
      </p>
    </div>
  </section>
)

export default Hero
