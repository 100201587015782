import React from "react"

import imageIndustries from "../../images/brand-vipco-industries.jpg"
import imageVisual from "../../images/brand-vipco-visual-boards.jpg"
import imageDecorative from "../../images/brand-vipco-decorative.jpg"
import styles from "./index.module.scss"

const BrandImages = () => (
  <section className={styles.brandImagesSection}>
    <div className={styles.imageWrapper}>
      <img src={imageIndustries} alt="" width="100%" />
    </div>
    <div className={styles.imageWrapper}>
      <img src={imageVisual} alt="" width="100%" />
    </div>
    <div className={styles.imageWrapper}>
      <img src={imageDecorative} alt="" width="100%" />
    </div>
  </section>
)

export default BrandImages
