import React from "react"

import styles from "./index.module.scss"
import photo from "../../images/decorative-photo.jpg"

const DecorativePhoto = () => (
  <section className={styles.photo}>
    <img src={photo} alt={""} width="100%" />
  </section>
)

export default DecorativePhoto
