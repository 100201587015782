import React from "react"

import styles from "./index.module.scss"
import logoVipco from "../../images/logo-vipco.svg"

const Contact = () => (
  <section className={styles.contact}>
    <h2 className={styles.contactHeading}>Contact Us</h2>
    <p className={styles.contactDescription}>
      Please visit our websites to learn more about our products.
    </p>
    <img className={styles.logo} src={logoVipco} alt={"Vipco Group Logo"} />
    <p className={styles.contactQuestion}>
      Have a question? Drop us a line at{" "}
      <a href="&#109;ailto&#58;&#105;%6&#69;&#102;o%40v&#105;%&#55;&#48;c&#37;6&#70;&#46;%6&#51;a">
        i&#110;fo&#64;vipco&#46;ca
      </a>
    </p>
  </section>
)

export default Contact
